import React, { useState } from "react";

export const Banner = (props) => {
  const [activeImage, setActiveImage] = useState([1, 2, 3, 4, 5]);

  return (
    <div>
      <div className="relative">
        <img className="mx-auto" src="./header.png" alt="" />
        <div className="flex items-center gap-3 absolute top-2 right-2">
          <i className="fa fa-facebook text-[#EF5252] hidden md:block"></i>
          <i className="fa fa-twitter text-[#EF5252] hidden md:block"></i>
          <div className="flex items-center">
            <input
              className="border border-secondary-solid h-5 outline-none px-3 text-xs hidden md:block"
              type="search"
              name=""
              id=""
            />
            <i className="fa fa-search text-white bg-[#001080] p-[4px] text-[12px] hidden md:block"></i>
          </div>
          <div className="flex text-xs gap-1">
            <p
              onClick={() => props.setRussian(false)}
              className="text-[#001080] hover:text-[#EF5252] cursor-pointer"
            >
              EN
            </p>
            |
            <p
              onClick={() => props.setRussian(true)}
              className="text-[#001080] hover:text-[#EF5252] cursor-pointer"
            >
              RU
            </p>
          </div>
        </div>
        <div className="absolute flex gap-5 top-[35%] -translate-y-[35%] left-1/2">
          <div
            onMouseEnter={() => setActiveImage(1)}
            onMouseLeave={() => setActiveImage(0)}
            className="mt-1 md:-mt-1 cursor-pointer"
          >
            <a href="#about">
              {activeImage === 1 ? (
                <img src="./menu/menu1-2.png" alt="" />
              ) : (
                <img src="./menu/menu1-1.png" alt="" />
              )}
            </a>
          </div>
          <div
            onMouseEnter={() => setActiveImage(2)}
            onMouseLeave={() => setActiveImage(0)}
            className="mt-1 md:mt-0 cursor-pointer"
          >
            <a href="#products">
              {activeImage === 2 ? (
                <img src="./menu/menu2-2.png" alt="" />
              ) : (
                <img src="./menu/menu2-1.png" alt="" />
              )}
            </a>
          </div>
          <div
            onMouseEnter={() => setActiveImage(5)}
            onMouseLeave={() => setActiveImage(0)}
            className="mt-1 cursor-pointer"
          >
            <a href="#partners">
              {activeImage === 5 ? (
                <img src="./menu/menu5-2.png" alt="" />
              ) : (
                <img src="./menu/menu5-1.png" alt="" />
              )}
            </a>
          </div>
          <div
            onMouseEnter={() => setActiveImage(3)}
            onMouseLeave={() => setActiveImage(0)}
            className="md:mt-2 mt-1 cursor-pointer"
          >
            <a href="#recommendation">
              {activeImage === 3 ? (
                <img src="./menu/menu3-2.png" alt="" />
              ) : (
                <img src="./menu/menu3-1.png" alt="" />
              )}
            </a>
          </div>
          <div
            onMouseEnter={() => setActiveImage(4)}
            onMouseLeave={() => setActiveImage(0)}
            className="md:mt-3 mt-1 cursor-pointer"
          >
            <a href="#contact">
              {activeImage === 4 ? (
                <img src="./menu/menu4-2.png" alt="" />
              ) : (
                <img src="./menu/menu4-1.png" alt="" />
              )}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
