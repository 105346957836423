import React from "react";

export const Footer = (props) => {
  return (
    <div>
      <div className="border-y-4 border-primary-solid py-3 mx-3 md:mx-0">
        <div className="text-primary-solid flex justify-center">
          <p>+77018076727</p>
        </div>
        <p className="text-primary-solid flex justify-center text-center">
          {props.russian
            ? `Если у Вас есть вопросы,предложения или жалобы ,пожалуйста просим обратиться
              на прямую к учредителю компании.`
            : `If you have any questions, concerns or any suggestions please contact
              with our founder here.`}
        </p>
        <div className="grid md:grid-cols-2 gap-10 pt-10 pb-5">
          <div className="flex flex-col gap-5">
            <input
              className="border border-secondary-solid rounded-sm px-2 py-1"
              type="text"
              placeholder={props.russian ? "Ваше Имя, Фамилия:" : "Full name:"}
            />
            <input
              className="border border-secondary-solid rounded-sm px-2 py-1"
              type="text"
              placeholder={props.russian ? "Телефон:" : "Tel:"}
            />
            <input
              className="border border-secondary-solid rounded-sm px-2 py-1"
              type="text"
              placeholder={props.russian ? "Факс:" : "Fax:"}
            />
            <input
              className="border border-secondary-solid rounded-sm px-2 py-1"
              type="text"
              placeholder={props.russian ? "И-майл" : "Email:"}
            />
            <input
              className="border border-secondary-solid rounded-sm px-2 py-1"
              type="text"
              placeholder={props.russian ? "Aдрес" : "Address:"}
            />
          </div>
          <div>
            <textarea
              className="border border-secondary-solid w-full p-2"
              placeholder={props.russian ? "Сообщение" : "Message"}
              name=""
              id=""
              cols="30"
              rows="8"
            ></textarea>
            <div className="grid grid-cols-2 gap-1">
              <button className="w-full bg-secondary-solid py-1 rounded-sm text-white hover:bg-[#333] transition-all">
                {props.russian ? "Отправить" : "Submit"}
              </button>
              <button className="w-full bg-secondary-solid py-1 rounded-sm text-white hover:bg-[#333] transition-all">
                {props.russian ? "Сброс" : "Reset"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="py-3 text-secondary-solid text-center text-xs">
        ©2015 SPK Train LLC. All right reserved.
      </p>
    </div>
  );
};
