import React from "react";

export const Button = (props) => {
  return (
    <>
      <div id={props.id} className="relative">
        <img className="" src="./buttonImage.png" alt="" />
        <p className="absolute top-[15%] -translate-y-[15%] left-1/2 -translate-x-1/2 text-[15px] text-white px-2">
          {props.text}
        </p>
      </div>
    </>
  );
};
