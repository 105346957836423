import React from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export const AboutSection = (props) => {
  const animate1 = {
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, scale: 1, x: -200, transition: { duration: 0.5 } },
  };
  const animate2 = {
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, scale: 1, x: 200, transition: { duration: 0.5 } },
  };

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  return (
    <div>
      <div className="flex flex-col md:flex-row items-center">
        <div className="relative z-10 w-1/2 md:w-[80%] md:ml-20 pb-5 md:pb-0">
          <img src="./container/image1.png" alt="" />
        </div>
        <div>
          <p className="border-y-4 border-primary-solid mx-3 md:mx-16 py-10 text-[#EF5252] text-2xl">
            {props.russian
              ? `Товарищество с ограниченной ответственностью «SPK-трэйн» основана 2008 году и является 
                официальным представительством генерального дистрибьютора Таншанского локомотивно-вагоностроительного 
                завода (КНР)`
              : `Limited Liability Partnership “SPK-Train” was founded in 2008 and is
                an official representative of the general distributor of CNR
                Tangshan Railway Vehicle Co., Ltd.`}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-5 pt-8">
        <motion.div
          ref={ref}
          variants={animate1}
          initial="hidden"
          animate={control}
          className="col-span-5 md:col-span-3 pb-5 md:pt-0 mx-3 md:mx-0"
        >
          <p className="text-primary-solid text-2xl">
            {props.russian ? "НАШЕ СТРЕМЛЕНИЕ" : "OUR ASPIRATION"}
          </p>
          <div className="text-base text-[#666]">
            <p>
              {props.russian
                ? `быть лучшими в своей сфере деятельности, обеспечивая предприятия 
                  железнодорожных дорог по всему миру надежными и высококачественными 
                  запасными частями, комплектующих и прочих расходных материалов на 
                  пассажирские и грузовые вагоны, электровозы и локомотивы.`
                : `— being a leading company in our field, by providing railway
                  enterprises with reliable and high-quality spare parts,
                  accessories and other consumables for passenger and freight wagons
                  (coaches), locomotives and electric locomotives all over the
                  world.`}
            </p>
            <p>
              {props.russian
                ? `обеспечить каждому клиенту возможность для постоянной и комфортной 
                  эксплуатации пассажирских и грузовых вагонов, электровозов и локомотивов 
                  с помощью предложения широкого ассортимента запасных частей.`
                : `—providing opportunity to each customer for constant and
                  comfortable exploitation of the passenger and freight wagons
                  (coaches) locomotives and electric locomotives via a wide range of
                  spare parts.`}
            </p>
          </div>
        </motion.div>
        <motion.div
          ref={ref}
          variants={animate2}
          initial="hidden"
          animate={control}
          className="col-span-5 md:col-span-2 border-t-4 md:border-t-0 pt-5 md:pt-0 mx-3 md:mx-0 md:border-l-4 border-primary-solid pl-7"
        >
          <p className="text-primary-solid text-2xl">
            {props.russian ? "НАШЕ ПРЕИМУЩЕСТВА" : "OUR ADVANTAGES"}
          </p>
          <div className="text-base text-[#666]">
            <p>
              {props.russian
                ? "- своевременное и оперативное выполнение заказов"
                : "- timely and efficient execution of orders"}
            </p>
            <p>
              {props.russian
                ? "- профессиональный подход к детальному изучению чертежей каждой запчасти"
                : "- professional approach towards detailed study for drawings of each spare parts"}
            </p>
            <p>
              {props.russian
                ? "- компетентное размещение заказов по месту назначения клиентов"
                : "- competent placing of orders by destination of customers"}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
