import React from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Images = [
  { imgPath: "./page1.png" },
  { imgPath: "./page2.png" },
  { imgPath: "./page3.png" },
];

export const CarouselImage = () => {
  return (
    <div>
      <Carousel
        autoPlay
        infiniteLoop
        transitionTime={800}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(clickHandler) => (
          <div
            className="w-5 md:w-14 absolute top-1/2 -translate-y-1/2 left-3 md:left-0 text-5xl cursor-pointer z-50 opacity-60 hover:opacity-100"
            onClick={clickHandler}
          >
            <img className="" src="./arrow_left.png" alt="" />
          </div>
        )}
        renderArrowNext={(clickHandler) => (
          <div
            className="w-5 md:w-14 absolute top-1/2 -translate-y-1/2 right-3 md:right-0 text-5xl cursor-pointer z-50 opacity-60 hover:opacity-100"
            onClick={clickHandler}
          >
            {" "}
            <img className="" src="arrow_right.png" alt="" />
          </div>
        )}
      >
        {Images.map((item, index) => (
          <div key={index} className="w-[90%] mx-auto">
            <img
              className="w-full h-full object-cover"
              src={item.imgPath}
              alt=""
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
