import React, { useState } from "react";
import "./App.css";

import { Banner } from "./components/banner";
import { Footer } from "./components/footer";
import { Container } from "./components/container";

function App() {
  const [russian, setRussian] = useState(false);

  return (
    <div>
      <Banner russian={russian} setRussian={setRussian} />
      <div className="max-w-[1100px] mx-auto">
        <Container russian={russian} />
        <Footer russian={russian} />
      </div>
    </div>
  );
}

export default App;
