import React from "react";

import { Button } from "./button";
import { AboutSection } from "./aboutSection";
import { CarouselImage } from "./carouselImage";
import { ProductSection } from "./productSection";

export const Container = (props) => {
  return (
    <div>
      <div className="w-full flex justify-center -mt-20 md:ml-48 pt-16 md:pt-0">
        <Button id="about" text={props.russian ? "О НАС" : "ABOUT"} />
      </div>
      <AboutSection russian={props.russian} />
      <div className="w-full flex py-5 md:py-16 md:ml-20 justify-center md:justify-start">
        <Button id="products" text={props.russian ? "ПРОДУКЦИЯ" : "PRODUCTS"} />
      </div>
      <ProductSection russian={props.russian} />
      <div className="w-full flex justify-center py-16">
        <Button id="partners" text={props.russian ? "ПАРТНЕРЫ" : "PARTNERS"} />
      </div>
      <div className="flex items-center border-y-4 border-primary-solid py-5 mx-3 md:mx-0">
        <div className="border-r-4 border-primary-solid md:h-20 float-left px-[10px] py-[10px] md:px-[60px] md:py-[15px]">
          <img className="h-full" src="./logos1.png" alt="" />
        </div>
        <div className="border-r-4 border-primary-solid md:h-20 float-left px-[10px] py-[10px] md:px-[60px] md:py-[15px]">
          <img className="h-full" src="./logos2.png" alt="" />
        </div>
        <div className="border-r-4 border-primary-solid md:h-20 float-left px-[10px] py-[10px] md:px-[60px] md:py-[15px]">
          <img className="h-full" src="./logos3.png" alt="" />
        </div>
        <div className="md:h-20 float-left px-[10px] py-[10px] md:px-[60px] md:py-[15px]">
          <img className="h-full" src="./logos4.png" alt="" />
        </div>
      </div>
      <div className="w-full flex justify-center py-16">
        <Button
          id="recommendation"
          text={props.russian ? "ОТЗЫВЫ" : "RECOMMENDATIONS"}
        />
      </div>
      <CarouselImage />
      <div className="w-full flex justify-center py-16">
        <Button id="contact" text={props.russian ? "КОНТАКТЫ" : "CONTACT"} />
      </div>
    </div>
  );
};
