import React from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const MotionComponent = (props) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  return (
    <motion.div
      ref={ref}
      variants={props.variants}
      animate={control}
      initial="hidden"
    >
      {props.children}
    </motion.div>
  );
};

export const ProductSection = (props) => {
  return (
    <div>
      <div className="grid grid-cols-3 items-center">
        <div className="text-2xl text-primary-solid col-span-3 md:col-span-1">
          <MotionComponent
            variants={{
              visible: {
                opacity: 1,
                scale: 1,
                x: 0,
                transition: { duration: 0.7 },
              },
              hidden: {
                opacity: 0,
                scale: 1,
                x: -200,
                transition: { duration: 0.5 },
              },
            }}
          >
            <div>
              <p className="border-y-4 border-primary-solid py-5 px-8 mx-3 md:mx-0">
                {props.russian
                  ? "ЗАПЧАСТИ ДЛЯ ЖЕЛЕЗНОДОРОЖНОГО ТРАНСПОРТА"
                  : "SPARE PARTS FOR THE RAIL TRANSPORT"}
              </p>
            </div>
          </MotionComponent>
        </div>
        <div className="px-5 col-span-3 md:col-span-1 py-5 md:py-0">
          <MotionComponent
            variants={{
              visible: { opacity: 1, scale: 1, transition: { duration: 0.7 } },
              hidden: { opacity: 0, scale: 0, transition: { duration: 0.5 } },
            }}
          >
            <div>
              <img src="./container/image2.png" alt="" />
            </div>
          </MotionComponent>
        </div>
        <div className="border-b-4 border-primary-solid col-span-3 md:col-span-1 mx-3 md:mx-0">
          <MotionComponent
            variants={{
              visible: {
                opacity: 1,
                scale: 1,
                x: 0,
                transition: { duration: 0.7 },
              },
              hidden: {
                opacity: 0,
                scale: 1,
                x: 200,
                transition: { duration: 0.5 },
              },
            }}
          >
            <div>
              <p className="text-2xl text-primary-solid pb-4">
                {props.russian
                  ? "Магистральныйлокомотив ТЭ33А"
                  : "Locomotive Evolution series TE33A"}
              </p>
              <p className="text-base text-[#666] pb-10">
                {props.russian
                  ? `Магистральный грузовой односекционный тепловоз ТЭ33А серии «Evolution» - технически передовой и экономичный тепловоз мощностью 3356кВт (4562 л.с.) с электрической передачей переменно-переменного тока с поосным регулированием силы тяги`
                  : `Evolution Series single-unit mainline freight locomotive TE33A
                    is technically advanced and cost-effective locomotive of rating
                    335 kw (4562hp) with electric`}
              </p>
            </div>
          </MotionComponent>
        </div>
      </div>
      <div className=" pt-5">
        <div className="grid grid-cols-4 items-center">
          <div className="col-span-4 md:col-span-1 justify-center flex">
            <MotionComponent
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  x: 0,
                  transition: { duration: 0.7 },
                },
                hidden: {
                  opacity: 0,
                  scale: 1,
                  x: -200,
                  transition: { duration: 0.5 },
                },
              }}
            >
              <div>
                <img src="./container/image3.png" alt="" />
              </div>
            </MotionComponent>
          </div>
          <div className="col-span-4 md:col-span-2 px-3 md:px-10 pt-5 md:pt-0">
            <p className="text-2xl text-primary-solid">
              {props.russian
                ? `Электровоз KZ4А`
                : `KZ4A High Speed Passenger Electric Locomotive`}
            </p>
            <p className="text-base text-[#666]">
              {props.russian
                ? `KZ4A Магистральный пассажирский высокоскоростной электровоз переменного 
                  тока производен по новейшим технологиям для «Қазақстан Темір Жолы»`
                : `KZ4A High Speed Passenger Electric Locomotive is a new type of AC
                  transmission electric locomotive specially designed and developed
                  for the National Railway Corporation of Kazakhstan. This
                  locomotive has integrate the latest technology in electric
                  locomotive and`}
            </p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 items-center md:-mt-24 mt-10 border-t-4 md:border-t-0 border-primary-solid mx-3 md:mx-0 pt-5 md:pt-0">
          <div></div>
          <div className="col-span-4 md:col-span-2 px-3 md:px-10">
            <p className="text-2xl text-primary-solid">
              {props.russian
                ? `Пассажирские вагоны «Тальго»`
                : `Passendger cars “TALGO”`}
            </p>
            <p className="text-base text-[#666]">
              {props.russian
                ? `Пассажирские скоростные вагоны «ТАЛЬГО» производится по технологиям 
                  «ТАЛЬГО» Испания. Облегченная конструкция, направляемые оси с независимыми 
                  колесами, низкий центр тяжести, сочлененная интеграция между вагонами`
                : `High speed passenger cars “TALGO” has made by technology “TALGO”
                  Spain`}
            </p>
          </div>
          <div className="col-span-4 md:col-span-1 mx-10 md:mx-0 pt-5 md:pt-0">
            <MotionComponent
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  x: 0,
                  transition: { duration: 0.7 },
                },
                hidden: {
                  opacity: 0,
                  scale: 1,
                  x: 200,
                  transition: { duration: 0.5 },
                },
              }}
            >
              <div>
                <img src="./container/image4.png" alt="" />
              </div>
            </MotionComponent>
          </div>
        </div>
        <div className="grid grid-cols-4 items-center md:-mt-24 mt-10 border-t-4 md:border-t-0 border-primary-solid mx-3 md:mx-0 pt-10 md:pt-0">
          <div className="col-span-4 md:col-span-1 mx-10 md:mx-0">
            <MotionComponent
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  x: 0,
                  transition: { duration: 0.7 },
                },
                hidden: {
                  opacity: 0,
                  scale: 1,
                  x: -200,
                  transition: { duration: 0.5 },
                },
              }}
            >
              <div>
                <img src="./container/image5.png" alt="" />
              </div>
            </MotionComponent>
          </div>
          <div className="col-span-4 md:col-span-2 px-3 md:px-10 pt-5 md:pt-0">
            <p className="text-2xl text-primary-solid">
              {props.russian ? "Электровоз KZ8А" : "Electric Locomotive KZ8A"}
            </p>
            <p className="text-base text-[#666]">
              {props.russian
                ? `Магистральный грузовой электровоз, разработанный французской компанией «Alstom»`
                : `Electric Lococmotive KZ8A has made by technology of “ALSTOM”`}
            </p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 items-center md:-mt-24 mt-10 border-t-4 md:border-t-0 border-primary-solid mx-3 md:mx-0 pt-10 md:pt-0">
          <div></div>
          <div className="col-span-4 md:col-span-2 px-3 md:px-10">
            <p className="text-2xl text-primary-solid">
              {props.russian
                ? `Вагоны Таншанского локомотивно-вагоностроительного завода (КНР)`
                : `Coach Tanghshan -China`}
            </p>
            <p className="text-base text-[#666]">
              {props.russian
                ? `Пассажирские вагоны Таншанского производства.60 спально-местные Скорость-140км в час`
                : `CoachSemi-cushioned berth sleeping 60 Design speed140 km/h`}
            </p>
          </div>
          <div className="col-span-4 md:col-span-1 mx-10 md:mx-0 pt-5 md:pt-0">
            <MotionComponent
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  x: 0,
                  transition: { duration: 0.7 },
                },
                hidden: {
                  opacity: 0,
                  scale: 1,
                  x: 200,
                  transition: { duration: 0.5 },
                },
              }}
            >
              <div>
                <img src="./container/image6.png" alt="" />
              </div>
            </MotionComponent>
          </div>
        </div>
      </div>
    </div>
  );
};
